"use client";
import React, { useEffect } from "react";

import { RootState } from "@/app/GlobalRedux/Store";
import { useDispatch, useSelector } from "react-redux";
import { getLandingPagePopularPosts } from "@/app/GlobalRedux/features/post/postSlice";

import Image from "next/image";
import Link from "next/link";

import { TiSocialLinkedinCircular } from "react-icons/ti";
import { IoLogoInstagram, IoLogoTwitter } from "react-icons/io";

import Loader from "@/app/(Global)/Components/Loader";

import { formattedDate } from "@/app/utils/date-format/formatDate";

import styling from "./Preview.module.scss";
import { AiFillEye } from "react-icons/ai";

interface PreviewProps {
  theme: string;
  isLoggedIn: boolean;
}

const Preview: React.FC<PreviewProps> = ({ theme }) => {
  const dispatch = useDispatch();

  const { isLoadingPost, posts } = useSelector(
    (state: RootState) => state.post
  );

  useEffect(() => {
    dispatch(getLandingPagePopularPosts() as any);
  }, [dispatch]);

  return (
    <main className={styling.preview}>
      <section className={styling.preview__info}>
        <div>
          <strong>Enjoy Smooth Navigation and Mobile-Friendly Design</strong>
          <p>
            Enjoy smooth navigation with my blog&apos;s responsive design,
            tailored for any device and navigate easily and dive into the
            content with just a few clicks.
          </p>
        </div>
        <div>
          <Image
            src="https://ik.imagekit.io/vinnie/BLOG/Landing%20Page/Responsiveness%20_2PzaPu8wT.png"
            alt="Preview"
            width={450}
            height={450}
          />
        </div>
      </section>
      <section className={styling.preview__info}>
        <div>
          <strong>Interact Easily with Comments and Likes</strong>
          <p>
            Get to join the conversation with our easy-to-use commenting system
            and like button. Share your thoughts, show appreciation, and connect
            with our content. Your input and feedback is valuable to us!
          </p>
        </div>
        <div>
          <Image
            src="https://ik.imagekit.io/vinnie/BLOG/Landing%20Page/comment_d_fHobaX0.png?updatedAt=1719500561335"
            alt="Vinnietec-Logo"
            width={450}
            height={450}
          />
        </div>
      </section>

      <section className={styling.preview__info}>
        <div>
          <strong>Stay Informed with Our Email Newsletter!</strong>
          <p>
            Stay informed with our email newsletter. Subscribe to receive
            updates, tips, and insights directly to your inbox. Join us and be
            part of our journey!
          </p>
        </div>
        <div>
          <Image
            src="https://ik.imagekit.io/vinnie/BLOG/Landing%20Page/landing%20page_oDzlc88Xa.jpg?updatedAt=1725570627402"
            alt="Vinnietec-Logo"
            width={450}
            height={450}
          />
        </div>
      </section>

      <>
        <h3 style={{ textAlign: "center", marginTop: "0.4rem" }}>
          Popular Articles
        </h3>
        {!isLoadingPost ? (
          <main className={styling.preview__posts}>
            {posts.map((post) => {
              const postUrl = `/blog/${post.slug}`;
              const postPreviewContent = post.metaDescription.slice(0, 150);
              return (
                <Link href={postUrl} key={post._id}>
                  <div className={styling.preview__posts__popularPost}>
                    <Image
                      src={post.featuredImage}
                      alt="Featured Image"
                      width={450}
                      height={450}
                    />
                    <div className={styling.preview__posts__popularPost__glass}>
                      <h2>{post.title}</h2>
                      <div>
                        <p>{formattedDate(post.publishedAt)}</p>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {post.allViews} <AiFillEye size={20} />
                        </p>
                      </div>
                      <aside>
                        <p>{postPreviewContent}...</p>
                      </aside>
                    </div>
                  </div>
                </Link>
              );
            })}
          </main>
        ) : (
          <Loader />
        )}
        <div
          style={{
            fontSize: "1.3rem",
            padding: "0.5rem",
            margin: "1rem",
            width: "auto",
            textAlign: "center",
          }}
        >
          <Link
            style={{
              textAlign: "center",
              borderRadius: "0.8em",
              border: "2px solid rgba(3, 6, 44, 0.95)",
              padding: "0.3rem",
            }}
            href="/blog"
          >
            See All Articles
          </Link>
        </div>
      </>

      <aside
        className={`${styling.preview__aside} ${
          theme === "dark" ? styling.darkMode : ""
        }`}
      >
        <h2>About The Developer and Author</h2>
        <div className={styling.preview__aside__1}>
          <div className={styling.preview__aside__1__A}>
            <div>
              <Image
                src="https://ik.imagekit.io/vinnie/Portfolio/pic.jpeg?updatedAt=1665492235704"
                alt="Author Pix"
                width={450}
                height={450}
              />
              <div>
                <strong>Agboola Vincent</strong>
                <p>Fullstack Developer</p>
              </div>
            </div>
            <p>
              Meet a full-stack developer passionate about Web Development and
              Mathematics, blending creativity and analytical thinking to craft
              innovative solutions. Join me as I simplify and share practical
              web development insights...
              <span>
                <a href="https://vinnietec.netlify.app/about">
                  Read more on my portfolio
                </a>
              </span>
            </p>
          </div>

          <div className={styling.preview__aside__1__B}>
            <h4>Connect with the Author</h4>
            <Link
              href="http://www.linkedin.com/in/agboola-vincent"
              aria-label="Linkedin Account"
            >
              <TiSocialLinkedinCircular size={30} />
            </Link>
            <Link
              href="https://www.instagram.com/vinnietec1/"
              aria-label="Instagram Account"
            >
              <IoLogoInstagram size={30} />
            </Link>
            <Link
              href="https://twitter.com/vinnietec"
              aria-label="Twitter Account"
            >
              <IoLogoTwitter size={30} />
            </Link>
          </div>
        </div>
      </aside>
    </main>
  );
};

export default Preview;
