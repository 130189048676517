import Hero from "./Hero";
import Preview from "./Preview";
import Services from "./Services";
import CTA from "./CTA";
import React from "react";

interface LandingPageProps {
  theme: string;
  isLoggedIn: boolean;
}

const LandingPage: React.FC<LandingPageProps> = ({ theme, isLoggedIn }) => {
  return (
    <>
      <Hero theme={theme} isLoggedIn={isLoggedIn} />
      <Preview theme={theme} isLoggedIn={isLoggedIn}  />
      <Services theme={theme} />
      <CTA theme={theme} />
    </>
  );
};

export default LandingPage;