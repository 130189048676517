import React from "react";

import { BsCodeSlash } from "react-icons/bs";
import { SiFramework7 } from "react-icons/si";
import { RxUpdate } from "react-icons/rx";
// import { GrServices } from "react-icons/gr";
import { TbArrowGuide } from "react-icons/tb";
// import { GrShieldSecurity } from "react-icons/gr";

import styling from "./Services.module.scss";

interface ServiceProp {
  theme: string;
}

const Services: React.FC<ServiceProp> = ({ theme }) => {
  return (
    <main
      className={`${styling.services} ${
        theme === "dark" ? styling.darkMode : ""
      }`}
    >
      <h2>The Services We Offer</h2>
      <section>
        <div>
          <h6>Offers Best Practices and Tips</h6>
          <p>
            Share best practices and tips to help Developers write cleaner, more
            efficient code. We simplify complex concepts for easy implementation
            in your projects.
          </p>
          <p>
            <BsCodeSlash size={20} />
          </p>
        </div>
        <div>
          <h6>Discuss Frameworks and Libraries</h6>
          <p>
            Explore popular JavaScript frameworks and libraries like
            React,Nodejs Angular, Vue.js, JQuery, etc. Get insights, tutorials,
            and comparisons to help you choose the best tools for your projects.
          </p>
          <p>
            <SiFramework7 size={20} />
          </p>
        </div>
        <div>
          <h6>Keep You up to date with New Features and Updates</h6>
          <p>
            Keep your audience up to date with the latest JavaScript features,
            updates, and specifications. Explain how these changes can enhance
            their development process.
          </p>
          <p>
            <RxUpdate size={20} />
          </p>
        </div>
        {/* <div>
          <h6>Offers Consulting and Mentoring</h6>
          <p>
            Offer consulting services for developers seeking guidance on
            JavaScript projects or challenges. Provide mentoring sessions to
            help developers grow and advance in their careers.
          </p>
          <p>
            <GrServices size={20} />
          </p>
        </div> */}
        <div>
          <h6>Provides Indepth Tutorials and Guides</h6>
          <p>
            Provide step-by-step tutorials and guides on various JavaScript
            topics, frameworks, libraries, or tools. This can help developers
            learn and improve their skills.
          </p>
          <p>
            <TbArrowGuide size={20} />
          </p>
        </div>
        {/* <div>
          <h6>Share Security Best Practices</h6>
          <p>
            Discuss common security vulnerabilities in JavaScript applications
            and provide tips on how to prevent them, such as input validation,
            secure authentication, and data protection.
          </p>
          <p>
            <GrShieldSecurity size={20} />
          </p>
        </div> */}
      </section>
    </main>
  );
};

export default Services;
