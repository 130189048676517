import React from "react";

import Link from "next/link";
import Image from "next/image";

import { IoLogoInstagram, IoLogoTwitter, IoLogoFacebook } from "react-icons/io";

import { IoLogoMedium, IoLogoLinkedin } from "react-icons/io5";

import Subscribe from "@/app/(Global)/email--lists/Components/Subscribe";

import styling from "./Footer.module.scss";

interface FooterProps {
  theme?: string;
  isLoggedIn?: boolean;
}
const Footer: React.FC<FooterProps> = ({ theme }) => {
  return (
    <footer
      className={`${styling.footer} ${
        theme === "dark" ? styling.darkMode : ""
      }`}
    >
      <div className={styling.footer__1}>
        <div className={styling.footer__A}>
          <Link href="/">
            <span>
              <Image
                src="https://ik.imagekit.io/vinnie/BLOG/Vinnie-tec_62aUaX5Hu.png?updatedAt=1684410998966"
                alt="Vinnietec-Logo"
                width={450}
                height={450}
              />
            </span>
            <span>
              <strong>Blog</strong>
            </span>
          </Link>
          <p>
            Welcome to our blog! Dive into web development with valuable tips
            and insights for all skill levels. Enhance your skills and stay
            ahead in the tech world.
          </p>
          <strong>Follow us online</strong>
          <ul>
            <li>
              <Link
                href="https://medium.com/@viptechmedia"
                aria-label="Medium Account"
              >
                <IoLogoMedium size={20} />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.linkedin.com/in/agboola-vincent"
                aria-label="Linkedin Account"
              >
                <IoLogoLinkedin size={20} />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.instagram.com/vinnietec1/"
                aria-label="Instagram Account"
              >
                <IoLogoInstagram size={20} />
              </Link>
            </li>
            <li>
              <Link href="https://x.com/vinnietec" aria-label="Twitter Account">
                <IoLogoTwitter size={20} />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.facebook.com/vinnieOlamide"
                aria-label="Facebook Account"
              >
                <IoLogoFacebook size={20} />
              </Link>
            </li>
          </ul>
        </div>
        <div className={styling.footer__B}>
          <h5>Blog</h5>
          <ul>
            <li>
              <Link href="/siteinfo/aboutus">About us</Link>
            </li>
            <li>
              <Link href="https://www.linkedin.com/in/agboola-vincent">
                Contact us
              </Link>
            </li>
            <li>
              <Link href="https://mail.google.com/mail/?view=cm&fs=1&to=vinnietec.blog@gmail.com">
                Email a Feedback
              </Link>
            </li>
            <li>
              <Link href="/siteinfo/privacypolicy">Privacy Policy</Link>
            </li>
            <li>
              <Link href="/siteinfo/terms&conditions">
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link href="https://wa.me/2348137297591?text=Good%20day%20Vinnietec%20blog,%20I%20need%20help%20on%20???">
                Help
              </Link>
            </li>
            <li>
              <Link href="/siteinfo/sitemap">Sitemap</Link>
            </li>
            <li>
              <Link href="/siteinfo/support">Support Us</Link>
            </li>
          </ul>
        </div>
        <div className={styling.footer__C}>
          <strong>
            Subscribe to our bi-weekly mailing list and stay updated with the
            latest tech tips!
          </strong>
          <p>
            Stay updated with the latest trends, coding techniques, and industry
            insights. Sign up now to enhance your web development skills and
            elevate your tech expertise!
          </p>
          <div>
            <Subscribe />
          </div>
        </div>
      </div>
      <hr />
      <div className={styling.footer__2}>
        <Link href="/">
          <span>
            <Image
              src="https://ik.imagekit.io/vinnie/BLOG/Vinnie-tec_62aUaX5Hu.png?updatedAt=1684410998966"
              alt="Vinnietec-Logo"
              width={450}
              height={450}
            />
          </span>
          <span>
            <strong>Blog</strong>
          </span>
        </Link>
        <p>&copy; {new Date().getFullYear()}. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
