import React from "react";
import styling from "./CTA.module.scss";
import Link from "next/link";

interface CTAProps {
  theme: string;
}

const CTA: React.FC<CTAProps> = ({ theme }) => {
  return (
    <section
      className={`${styling.CTA} ${theme === "dark" ? styling.darkMode : ""}`}
    >
      <div className={styling.CTA__1}>
        <h2>Why sign up with us?</h2>
        <div>
          {/* <div>
            <p>✍️</p>
            <p>
              Stay updated with the latest trends and advancements in
              JavaScript.
            </p>
          </div> */}
          {/* <div>
            <p>✍️</p>
            <p>
              Get valuable insights and practical knowledge to boost your coding
              skills.
            </p>
          </div> */}
          <div>
            <p>✍️</p>
            <p>
              Discover industry best practices and optimize your development
              workflow.
            </p>
          </div>
          {/* <div>
            <p>✍️</p>
            <p>
              Engage with a vibrant community of JavaScript enthusiasts and
              share your thoughts.
            </p>
          </div> */}
          <div>
            <p>✍️</p>
            <p>
              Dive into the world of JavaScript and elevate your coding
              expertise.
            </p>
          </div>
          <div>
            <p>✍️</p>
            <p>
              Join our community of passionate developers and embark on a
              journey of continuous learning.
            </p>
          </div>
        </div>
      </div>
      <div className={styling.CTA__2}>
        <h3>
          Sign up now to access our exclusive content library and advance your
          skills!
        </h3>
        <strong style={{ textAlign: "center" }}>
          <Link href="/auth/signup">Sign Up</Link>
        </strong>
      </div>
    </section>
  );
};

export default CTA;
