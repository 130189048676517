"use client";
import React, { useEffect } from "react";

// importing redux
import type { RootState } from "@/app/GlobalRedux/Store";
import { useSelector, useDispatch } from "react-redux";
import { getLoginStatus } from "@/app/GlobalRedux/features/auth/authSlice";

import Header from "@/app/(Global)/Components/HomeHeader";
import LandingPage from "@/app/Home/Components/Home";
import Footer from "@/app/(Global)/Components/Footer";

export default function HomePage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getLoginStatus() as any);
    };
    fetchData();
  }, [dispatch]);

  const theme = useSelector((state: RootState) => state.theme.themeValue);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  return (
    <>
      <Header theme={theme} isLoggedIn={isLoggedIn} />
      <LandingPage theme={theme} isLoggedIn={isLoggedIn} />
      <Footer theme={theme} isLoggedIn={isLoggedIn} />
    </>
  );
}
