import React from "react";

import Link from "next/link";
import Image from "next/image";

import styling from "./Hero.module.scss";

interface HeroProps {
  theme: string;
  isLoggedIn: boolean;
}

const Hero: React.FC<HeroProps> = ({ theme }) => {
  return (
    <main
      className={`${styling.hero} ${theme === "dark" ? styling.darkMode : ""}`}
    >
      <div>
        <h1>
          Elevate Your Web Development Journey And
          <br /> Unleash your Full-Stack Potential
        </h1>
        <p>
          Discover the latest in JavaScript Web Development. From beginner
          tutorials to expert insights, our blog offers practical techniques and
          tips to keep you on the cutting edge. There&apos;s always more to
          explore with every visit.
        </p>
        <div>
          <Link href="/blog">Explore Articles</Link>
        </div>
        <aside>
          <Image
            src="https://ik.imagekit.io/vinnie/BLOG/Landing%20Page/postpageview_ezs1f_Lkr.png?updatedAt=1720535038534"
            alt="Vinnietec-Logo"
            width={450}
            height={450}
          />
        </aside>
      </div>
    </main>
  );
};

export default Hero;
